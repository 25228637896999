/* NOTE: DO NOT ADD COLORS WITHOUT EXTREMELY GOOD REASON AND DESIGN REVIEW
 * NOTE: KEEP SYNCRONIZED WITH:
 * frontend/src/metabase/ui/utils/colors.ts
 * frontend/src/metabase/styled-components/containers/GlobalStyles/GlobalStyles.tsx
 * enterprise/frontend/src/embedding-sdk/components/private/SdkContentWrapper.tsx
 * .storybook/preview-head.html
 */
:root {
  --color-white: #fff;
  --mb-color-brand: #509ee3;
  --mb-color-brand-light: #f9fbfc;
  --mb-color-brand-lighter: #eef6fc;
  --color-success: #84bb4c;
  --mb-color-summarize: #88bf4d;
  --mb-color-error: #ed6e6e;
  --mb-color-danger: #ed6e6e;
  --mb-color-text-dark: #4c5773;
  --mb-color-text-medium: #696e7b;
  --mb-color-text-light: #949aab;
  --color-text-white: #fff;
  --mb-color-bg-black: #2e353b;
  --mb-color-bg-dark: #93a1ab;
  --mb-color-bg-medium: #edf2f5;
  --mb-color-bg-light: #f9fbfc;
  --mb-color-bg-white: #fff;
  --mb-color-bg-error: #ed6e6e55;
  --mb-color-bg-night: #42484e;
  --mb-color-shadow: rgba(0, 0, 0, 0.13);
  --mb-color-border: #eeecec;
  --mb-color-filter: #7172ad;
  --mb-color-focus: #cbe2f7;
}

.textDefault {
  color: var(--mb-color-text-dark);
}

.textBrand {
  color: var(--mb-color-brand);
}

.textSuccess {
  color: var(--color-success);
}

/* error */

.textError,
.textErrorHover {
  color: var(--mb-color-error);
}

.bgErrorHover:hover {
  background-color: var(--mb-color-error);
}

.bgErrorInput {
  background-color: var(--mb-color-bg-white);
}

.textSlate {
  color: var(--mb-color-text-medium);
}

.textSlateLight {
  color: var(--mb-color-text-light);
}

.textLight {
  color: var(--mb-color-text-light);
}

.textMedium,
.textMediumHover:hover {
  color: var(--mb-color-text-medium);
}

.textDark,
.textDarkHover:hover {
  color: var(--mb-color-text-dark);
}

.bgLight,
.bgLightHover:hover {
  background-color: var(--mb-color-bg-light);
}

.bgMedium {
  background-color: var(--mb-color-bg-medium);
}

/* white  - move to bottom for specificity since its often used on hovers, etc */
.textWhite,
.textWhiteHover:hover {
  color: var(--color-text-white);
}

.bgWhite {
  background-color: var(--mb-color-bg-white);
}

.bgLightBlue {
  background-color: var(--mb-color-bg-light);
}

.DashboardNight .bgLight {
  background-color: var(--mb-color-bg-black);
}

.DashboardNight .bgMedium {
  background-color: #596269;
}

.DashboardNight .textDark {
  color: var(--mb-color-bg-light);
}
